import everplansLogo from '../../../../shared_assets/everplans-logo-indigo.svg'

import './deputyFeedbackHeader.scss'

export const DeputyFeedbackHeader = () => (
  <header className='deputy-feedback-header'>
    <img src={everplansLogo} alt='everplans-logo' className='logo' />
  </header>
)

export default DeputyFeedbackHeader
